<template>
  <v-container>
    <breadcrumbs class="mb-4" :items="breadcrumbs"></breadcrumbs>
    <v-sheet class="pa-8 mb-8">
      <div class="text-center">
        <h1 class="mb-5">{{ $t("concept-heading") }}</h1>
      </div>
      <div class="text-center mb-5">
        <p v-for="paragraph in $t('concept-text')" :key="paragraph">
          {{ paragraph }}
        </p>
      </div>
      <v-row>
        <v-col
          v-for="(button, index) in this.buttons"
          :key="index"
          align="center"
        >
          <v-btn color="accent" :to="button.url">
            <v-icon class="mr-1">{{ button.icon }}</v-icon>
            {{ $t(button.slug) }}
          </v-btn>
        </v-col>
      </v-row>
    </v-sheet>
    <div class="text-center">
      <h1 class="mb-5">{{ $t("faq-heading") }}</h1>
    </div>
    <v-expansion-panels class="mb-5">
      <v-expansion-panel v-for="(faq, index) in $t('faq')" :key="index">
        <v-expansion-panel-header>
          {{ $t(faq.question) }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          {{ $t(faq.answer) }}
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row>
      <v-col align="center">
        <v-btn class="mb-3" color="accent" to="/contact">
          <v-icon class="mr-1">mdi-email-edit-outline</v-icon>
          {{ $t("concept-button-mail") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  name: "concept",
  components: {
    Breadcrumbs
  },
  data: () => ({
    buttons: [
      {
        slug: "location",
        icon: "mdi-door-open",
        url: "/group/location"
      },
      {
        slug: "godparent-system",
        icon: "mdi-account-heart-outline",
        url: "/group/godparent"
      }
    ],
    faqs: [
      {
        question: "faq-question-1",
        answer: "faq-answer-1"
      },
      {
        question: "faq-question-2",
        answer: "faq-answer-2"
      },
      {
        question: "faq-question-3",
        answer: "faq-answer-3"
      },
      {
        question: "faq-question-4",
        answer: "faq-answer-4"
      },
      {
        question: "faq-question-5",
        answer: "faq-answer-5"
      }
    ]
  }),
  computed: {
    breadcrumbs: function() {
      return [
        {
          text: this.$t("home"),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("group"),
          disabled: true
        }
      ];
    }
  }
};
</script>
